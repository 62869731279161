import { styled } from 'styled-components';
import React from 'react';
import Socials from './Socials';

export default function NewAlbum() {
  return (
    <StyledAlbum>
      <Socials />

      <a
        className="album"
        href="https://li.sten.to/wasdufuermichbist"
        target="_blank"
        rel="noreferrer"
      >
        <span className="subtitle">Was du für mich bist</span>
        <img
          className="cover"
          src="img/new-single.jpeg"
          alt="Cover of new single"
        />
        <span className="subtitle">Out Now</span>
      </a>
    </StyledAlbum>
  );
}

const StyledAlbum = styled.div`
  padding-top: 5vh;
  padding-bottom: 5vh;
  background-color: var(--bg-dark-0);

  a {
    text-decoration: none;
  }

  .album {
    min-height: 70svh;
    margin-top: 10vh;
    margin-bottom: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5vh;

    .cover {
      width: 50vw;
      height: 50vw;
      box-shadow: 0 0 5vh #78008555;
    }

    .subtitle {
      color: var(--fg-light-1);
      font-size: 6vw;
      font-family: var(--font-1);
      font-weight: 400;
      font-style: normal;
    }
  }
`;
