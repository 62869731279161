import { styled } from 'styled-components';
import React from 'react';
import { bpDesktopQuery } from '../libs/breakpoints';

interface SocialsProps {
  style?: React.CSSProperties;
}

export default function Socials({ style }: SocialsProps) {
  return (
    <StyledSocials style={style}>
      <a
        href="https://www.instagram.com/the_jeals/"
        target="_blank"
        rel="noreferrer"
      >
        <img className="logo" alt="instagram" src="img/instagram.svg"></img>
      </a>
      <a
        href="https://www.youtube.com/channel/UC9sZE0gHVLLdLA8U6tdMCCQ"
        target="_blank"
        rel="noreferrer"
      >
        <img className="logo" alt="youtube" src="img/youtube.svg"></img>
      </a>
      <a href="https://facebook.com/thejeals" target="_blank" rel="noreferrer">
        <img className="logo" alt="facebook" src="img/facebook.svg"></img>
      </a>
      <a
        href="https://open.spotify.com/artist/1Nf04uQQtOX7PD59A1L7Wh"
        target="_blank"
        rel="noreferrer"
      >
        <img className="logo" alt="spotify" src="img/spotify.svg"></img>
      </a>
    </StyledSocials>
  );
}

const StyledSocials = styled.div`
  display: flex;
  height: 13vw;
  width: 100%;
  justify-content: space-evenly;

  .logo {
    height: 100%;
    width: 13vw;
  }

  a {
    transition: all 0.1s ease-in;
  }

  a:hover {
    scale: 1.1;
    transform: rotate(5deg);
  }

  @media ${bpDesktopQuery} {
    height: 10vh;
    gap: 7vw;
    width: unset;
    justify-content: center;
  }
`;
